import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons'; // Import the chat icon
import './ChatboxIcon.css';

function ChatboxIcon() {
  // Function to handle the click event
  const handleClick = () => {
    alert("Chat functionality coming soon!");
  };

  return (
    <div className="chatbox-icon" onClick={handleClick}>
      <FontAwesomeIcon icon={faComment} />
    </div>
  );
}

export default ChatboxIcon;
