import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { CurrencyProvider } from './contexts/CurrencyContext';
import { CartProvider } from './contexts/CartContext'; // Import CartProvider
import 'flag-icons/css/flag-icons.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CurrencyProvider>
      <CartProvider> {/* Wrap App in CartProvider */}
        <App />
      </CartProvider>
    </CurrencyProvider>
  </React.StrictMode>
);
