import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/CartContext'; // Hook to get cart items
import { FaShoppingCart } from 'react-icons/fa'; // Import cart icon
import './Header.css';
import logo from '../assets/images/LogoD.png';

function Header() {
  const { cart } = useCart(); // Get cart from context

  // Calculate total items in cart
  const totalItems = (cart.items || []).reduce((total, item) => total + item.quantity, 0);

  console.log(cart.items); // Check cart items
  console.log(totalItems); // Check total items

  return (
    <header className="site-header">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Amali Couture Logo" />
        </Link>
      </div>

      <div className="header-content">
        <h1>AMALI COUTURE</h1>
        <nav className="site-nav">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/products">Products</Link></li>
            <li><Link to="/checkout">Checkout</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/admin">Admin</Link></li>
          </ul>
        </nav>
      </div>

      <div className="cart-icon">
        <Link to="/cart">
          <FaShoppingCart size={30} />
          {totalItems > 0 && <span className="cart-count">{totalItems}</span>} {/* Show cart count */}
        </Link>
      </div>
    </header>
  );
}

export default Header;
