// src/contexts/CurrencyContext.js
import React, { createContext, useContext, useState } from 'react';

const CurrencyContext = createContext();

export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState('KSHS'); // Default currency
  const conversionRates = {
    USD: 0.0092,
    TZS: 21.38,
    UGX: 34.12,
    EUR: 0.0078,
    GBP: 0.0068,
  };

  const exchangeRates = {
    KSHS: 1,
    ...conversionRates,
  };

  const convertPrice = (priceInKShs) => {
    const rate = exchangeRates[currency] || 1; // Default to 1 if currency is KSHS
    return priceInKShs * rate;
  };

  const getCurrencySymbol = (currency) => {
    const symbols = {
      KSHS: 'KShs',
      USD: '$',
      TZS: 'TSh',
      UGX: 'UGX',
      EUR: '€',
      GBP: '£',
    };
    return symbols[currency] || '';
  };

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const value = {
    currency,
    setCurrency,
    convertPrice,
    exchangeRates,
    getCurrencySymbol,
    formatPrice, // Add formatPrice to context
  };

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};
