import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import './AddPickupLocation.css'; // Custom styling

const AddPickupLocation = () => {
  const [region, setRegion] = useState('');
  const [area, setArea] = useState('');
  const [subArea, setSubArea] = useState('');
  const [name, setName] = useState(''); // Changed from agentName to name
  const [price, setPrice] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple validation
    if (!region || !area || !subArea || !name || !price) {
      setError('All fields are required.');
      return;
    }

    if (isNaN(price) || parseFloat(price) <= 0) {
      setError('Price must be a positive number.');
      return;
    }

    // Updated newPickupLocation structure
    const newPickupLocation = {
      region,
      area,
      sub_area: subArea, // Ensure sub_area matches the backend
      name, // This corresponds to the name expected by the backend
      price: parseFloat(price), // Send price as a number
    };

    setLoading(true); // Start loading
    setError(''); // Clear previous error messages
    setSuccess(''); // Clear previous success messages

    try {
      const response = await fetch('http://localhost:5000/api/pickup-locations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPickupLocation),
      });

      const data = await response.json();

      if (!response.ok) {
        // Handle server errors
        setError(data.error || 'Failed to add pickup location. Please check your input.');
      } else {
        // Handle success response
        setSuccess('Pickup location added successfully!');
        // Clear form fields
        setRegion('');
        setArea('');
        setSubArea('');
        setName(''); // Reset name field
        setPrice('');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to add pickup location. Please try again later.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Card className="add-pickup-location-card">
      <Card.Body>
        <h2>Add Pickup Location</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formRegion">
            <Form.Label>Region</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter region"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              required
              disabled={loading} // Disable input when loading
            />
          </Form.Group>

          <Form.Group controlId="formArea">
            <Form.Label>Area</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter area"
              value={area}
              onChange={(e) => setArea(e.target.value)}
              required
              disabled={loading} // Disable input when loading
            />
          </Form.Group>

          <Form.Group controlId="formSubArea">
            <Form.Label>Sub-Area</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter sub-area"
              value={subArea}
              onChange={(e) => setSubArea(e.target.value)}
              required
              disabled={loading} // Disable input when loading
            />
          </Form.Group>

          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name} // Use name instead of agentName
              onChange={(e) => setName(e.target.value)} // Update to setName
              required
              disabled={loading} // Disable input when loading
            />
          </Form.Group>

          <Form.Group controlId="formPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              min="0" // Prevent negative values
              required
              disabled={loading} // Disable input when loading
            />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Adding...' : 'Add Location'}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddPickupLocation;
