import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './AdminDashboard.css';

const AdminDashboard = () => {
  return (
    <Container className="admin-dashboard">
      <Row className="admin-intro">
        <Col>
          <h1>Admin Dashboard</h1>
          <p>Manage your products, orders, pickup locations, and more from here.</p>
        </Col>
      </Row>
      <Row className="admin-section">
        <Col md={4} className="admin-card" style={{ '--i': 1 }}>
          <h3>Add New Product</h3>
          <Link to="/admin/add-product">
            <Button className="btn-primary">Go to Add Product</Button>
          </Link>
        </Col>
        <Col md={4} className="admin-card" style={{ '--i': 2 }}>
          <h3>Manage Products</h3>
          <Link to="/admin/manage-products">
            <Button className="btn-primary">Manage Products</Button>
          </Link>
        </Col>
        <Col md={4} className="admin-card" style={{ '--i': 3 }}>
          <h3>Manage Orders</h3>
          <Link to="/admin/manage-orders">
            <Button className="btn-primary">Manage Shop Orders</Button>
          </Link>
        </Col>
        <Col md={4} className="admin-card" style={{ '--i': 4 }}>
          <h3>Add Pickup Location</h3>
          <Link to="/admin/add-pickup-location">
            <Button className="btn-primary">Add Pickup Location</Button>
          </Link>
        </Col>
        <Col md={4} className="admin-card" style={{ '--i': 5 }}>
          <h3>Manage Locations</h3>
          <Link to="/admin/manage-pickup-locations">
            <Button className="btn-primary">Manage Locations</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
