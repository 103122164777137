import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './HomePage.css'; // Import the CSS file  

// Import images
import ankaraFabricImage from '../assets/images/ankara-fabric.jpg';
import fashionFindsImage from '../assets/images/fashion-finds.jpg';
import headGlamImage from '../assets/images/head-glam.jpg';
import chicEssentialsImage from '../assets/images/chic-essentials.jpg';

const HomePage = () => {
  return (
    <Container className="home-page">
      <Row className="home-intro">
        <Col>
          <h1>Welcome to Our Store!</h1>
        </Col>
      </Row>
      <Row className="home-category-section">
        <Col md={3} className="category-card" style={{ '--i': 1 }}>
          <Link to="/category/Ankara%20Fabric" className="category-link">
            <img src={ankaraFabricImage} alt="Ankara Fabric" />
            <h3 className="category-title">Ankara Fabric</h3>
          </Link>
        </Col>
        <Col md={3} className="category-card" style={{ '--i': 2 }}>
          <Link to="/category/Fashion%20Finds" className="category-link">
            <img src={fashionFindsImage} alt="Fashion Finds" />
            <h3 className="category-title">Fashion Finds</h3>
          </Link>
        </Col>
        <Col md={3} className="category-card" style={{ '--i': 3 }}>
          <Link to="/category/Head%20Glam" className="category-link">
            <img src={headGlamImage} alt="Head Glam" />
            <h3 className="category-title">Head Glam</h3>
          </Link>
        </Col>
        <Col md={3} className="category-card" style={{ '--i': 4 }}>
          <Link to="/category/Chic%20Essentials" className="category-link">
            <img src={chicEssentialsImage} alt="Chic Essentials" />
            <h3 className="category-title">Chic Essentials</h3>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
