import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { useCurrency } from '../contexts/CurrencyContext';
import './CheckoutPage.css';
import mpesaIcon from '../assets/mpesa-icon.png';


const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidMobileNumber = (number) => {
  const mobileNumberRegex = /^\d{10}$/;
  return mobileNumberRegex.test(number);
};

const CheckoutPage = () => {
  const { cart, clearCart } = useCart();
  const { convertPrice, currency, getCurrencySymbol, formatPrice } = useCurrency();
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('mpesa');
  const [shippingMethod, setShippingMethod] = useState('free-pickup');
  const [pickupCost, setPickupCost] = useState(0);
  const [emailError, setEmailError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [mpesaNumber, setMpesaNumber] = useState('');

  // Pickup locations state
  const [pickupLocations, setPickupLocations] = useState([]);
  const [region, setRegion] = useState('');
  const [area, setArea] = useState('');
  const [subArea, setSubArea] = useState('');
  const [filteredAreas, setFilteredAreas] = useState([]);
  const [filteredSubAreas, setFilteredSubAreas] = useState([]);

  // Mtaani agents state
  const [mtaaniAgents, setMtaaniAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');

  // Fetch pickup locations on component mount
  useEffect(() => {
    const fetchPickupLocations = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/pickup-locations');
        setPickupLocations(response.data);
      } catch (error) {
        console.error('Error fetching pickup locations:', error);
      }
    };
    fetchPickupLocations();
  }, []);

  // Handle region selection
  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;
    setRegion(selectedRegion);
    const filteredAreas = pickupLocations
      .filter(location => location.region === selectedRegion)
      .map(location => location.area);
    setFilteredAreas([...new Set(filteredAreas)]);
    setArea('');
    setSubArea('');
    setFilteredSubAreas([]);
  };

  // Handle area selection
  const handleAreaChange = (e) => {
    const selectedArea = e.target.value;
    setArea(selectedArea);
    const filteredSubAreas = pickupLocations
      .filter(location => location.region === region && location.area === selectedArea)
      .map(location => location.sub_area);
    setFilteredSubAreas([...new Set(filteredSubAreas)]);
    setSubArea('');
  };

  // Handle sub-area selection
  const handleSubAreaChange = (e) => {
    const selectedSubArea = e.target.value;
    setSubArea(selectedSubArea);

    // Fetch Mtaani agents for the selected sub-area
    const agentsForSubArea = pickupLocations.filter(location => 
      location.region === region && location.area === area && location.sub_area === selectedSubArea
    ).map(location => ({
      name: location.name,
      price: location.price
    }));

    setMtaaniAgents(agentsForSubArea);
    setSelectedAgent('');
  };

  // Handle checkout process
  const handleCheckout = () => {
    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    } else {
      setEmailError('');
    }

    if (!isValidMobileNumber(mobileNumber)) {
      setMobileNumberError('Please enter a valid 10-digit mobile number.');
      return;
    } else {
      setMobileNumberError('');
    }

    if (!subArea && shippingMethod === 'pickup-mtaani') {
      alert('Please select a pickup location.');
      return;
    }

    alert('Checkout complete! Thank you for your purchase.');
    clearCart();
  };

  const calculateTotal = () => {
    const itemTotal = cart.items.reduce((total, item) => total + convertPrice(item.price) * item.quantity, 0);
    return itemTotal + pickupCost;
  };
  
  // Function to handle STK Push
const handleMpesaPayment = async () => {
  if (!isValidMobileNumber(mpesaNumber)) {
    setMobileNumberError('Please enter a valid 10-digit mobile number for M-Pesa payment.');
    return;
  } else {
    setMobileNumberError('');
  }

  try {
    // Call backend API to trigger the STK Push
    const response = await axios.post('http://localhost:5000/api/mpesa/stkpush', {
      amount: calculateTotal(),
      phoneNumber: mpesaNumber,
    });

    if (response.data.success) {
      alert('STK Push request sent! Please complete the payment on your phone.');
    } else {
      alert('Payment failed. Please try again.');
    }
  } catch (error) {
    console.error('Error initiating M-Pesa STK Push:', error);
    alert('There was an issue with the payment. Please try again later.');
  }
};

  return (
    <div className="checkout-page">
      <h1 className="checkout-header">CHECKOUT</h1>
      {cart.items.length === 0 ? (
        <p>Your cart is empty. <Link to="/">Start shopping</Link>.</p>
      ) : (
        <Card className="checkout-card">
          <Card.Body>
            <Form className="checkout-form">
              {/* Customer Details */}
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@example.com"
                  isInvalid={!!emailError}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formMobileNumber">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder="Enter your mobile number"
                  isInvalid={!!mobileNumberError}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {mobileNumberError}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Shipping Method */}
              <Form.Group>
                <Form.Label>Shipping Method</Form.Label>
                <Form.Check
                  type="radio"
                  label="Free Pickup in CBD"
                  value="free-pickup"
                  checked={shippingMethod === 'free-pickup'}
                  onChange={(e) => {
                    setShippingMethod(e.target.value);
                    setPickupCost(0); // Set pickup cost to zero for free pickup
                  }}
                />
                {shippingMethod === 'free-pickup' && (
                  <div style={{ color: '#86C232', marginBottom: '10px'  }}>
                  <p style={{ margin: '0', color: '#013220' }}>Location:</p>
                  <p style={{ margin: '0', color: '#86C232' }}>NAIROBI CBD, MOI-AVENUE</p>
                  <p style={{ margin: '0', color: '#86C232' }}>SOKO HUB STORE</p>
                  <p style={{ margin: '0', color: '#86C232' }}>SASA MALL - SHOP C6</p>
                </div>
                
                
                )}
                <Form.Check
                  type="radio"
                  label="Pickup Mtaani"
                  value="pickup-mtaani"
                  checked={shippingMethod === 'pickup-mtaani'}
                  onChange={(e) => setShippingMethod(e.target.value)}
                />
              </Form.Group>

              {/* Pickup Location Dropdowns */}
              {shippingMethod === 'pickup-mtaani' && (
                <>
                  <Form.Group controlId="formRegion">
                    <Form.Label>Choose Region</Form.Label>
                    <Form.Control as="select" value={region} onChange={handleRegionChange}>
                      <option value="">Select Region</option>
                      {[...new Set(pickupLocations.map(location => location.region))].map((region, index) => (
                        <option key={index} value={region}>{region}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  {region && (
                    <Form.Group controlId="formArea">
                      <Form.Label>Choose Area</Form.Label>
                      <Form.Control as="select" value={area} onChange={handleAreaChange}>
                        <option value="">Select Area</option>
                        {filteredAreas.map((area, index) => (
                          <option key={index} value={area}>{area}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}

                  {area && (
                    <Form.Group controlId="formSubArea">
                      <Form.Label>Choose Sub-Area</Form.Label>
                      <Form.Control as="select" value={subArea} onChange={handleSubAreaChange}>
                        <option value="">Select Sub-Area</option>
                        {filteredSubAreas.map((subArea, index) => (
                          <option key={index} value={subArea}>{subArea}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}

                  {subArea && mtaaniAgents.length > 0 && (
                    <Form.Group>
                      <Form.Label>Select Agent</Form.Label>
                      {mtaaniAgents.map((agent, index) => (
                        <Form.Check
                          key={index}
                          type="radio"
                          label={`${agent.name} - ${formatPrice(agent.price)}`}
                          value={agent.name}
                          checked={selectedAgent === agent.name}
                          onChange={() => {
                            setSelectedAgent(agent.name);
                            setPickupCost(agent.price);
                          }}
                        />
                      ))}
                    </Form.Group>
                  )}
                </>
              )}

              {/* Order Summary */}
              <h4 style={{ marginTop: '20px', textTransform: 'uppercase' }}>Order Summary</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.items.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <div className="cart-product">
                          <img src={`http://localhost:5000/${item.image}`} alt={item.name} className="order-summary-image" />
                          <span style={{ marginLeft: '20px' }}>{item.name}</span>
                        </div>
                      </td>
                      <td>{formatPrice(convertPrice(item.price))} {getCurrencySymbol(currency)}</td>
                      <td>{item.quantity}</td>
                      <td>{formatPrice(convertPrice(item.price) * item.quantity)} {getCurrencySymbol(currency)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3} style={{ fontWeight: 'bold', fontSize: '16px', color: 'green' }}>Shipping</td>
                    <td style={{ fontWeight: 'bold', fontSize: '16px', color: 'green' }}>{formatPrice(pickupCost)} {getCurrencySymbol(currency)}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{ fontWeight: 'bold', fontSize: '32px', color: 'green' }}>Total</td>
                    <td style={{ fontWeight: 'bold', fontSize: '32px', color: 'green' }}>{formatPrice(calculateTotal())} {getCurrencySymbol(currency)}</td>
                  </tr>
                </tbody>
              </Table>

              {/* Payment Section */}
              <h4 style={{ marginTop: '20px', textTransform: 'uppercase' }}>Payment</h4>
              <Form.Group controlId="formPaymentMethod">
                <div className="mpesa-payment">
                <img src={mpesaIcon} alt="M-Pesa" style={{ width: '150px', height: 'auto' }} className="mpesa-icon" />
                </div>
                <Form.Control
                  type="text"
                  value={mpesaNumber}
                  onChange={(e) => setMpesaNumber(e.target.value)}
                  placeholder="Enter M-Pesa phone number"
                  isInvalid={!!mobileNumberError}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {mobileNumberError}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Pay Now Button */}
              <Button 
                variant="success"
                onClick={handleMpesaPayment}
                disabled={!mpesaNumber}
              >
                Pay Now
              </Button>
            </Form>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default CheckoutPage;