import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './EditProduct.css'; // Ensure to import the CSS file for styling

const EditProduct = () => {
  const { id } = useParams(); // Get product ID from URL
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    sku: '',
    stock: '',
    image: '' // Holds the current image path
  });
  const [loading, setLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState(''); // Preview of the image
  const [selectedImage, setSelectedImage] = useState(null); // Holds the new image file, if any
  const [error, setError] = useState(''); // State for error messages
  const navigate = useNavigate();
  const baseURL = 'http://localhost:5000/api/products'; // Base URL for API

  // Fetch product details by ID
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${baseURL}/${id}`);
        setProduct(response.data);
        setImagePreview(`http://localhost:5000/${response.data.image}`);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Failed to fetch product details.');
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setImagePreview(URL.createObjectURL(file)); // Show preview for the selected image
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!product.name || !product.price || !product.category || !product.sku || !product.stock) {
      setError('Please fill out all required fields.');
      return;
    }

    const formData = new FormData();
    
    // Append fields to formData
    formData.append('name', product.name);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('category', product.category);
    formData.append('sku', product.sku);
    formData.append('stock', product.stock);

    // Append new image or keep the existing one
    if (selectedImage) {
      formData.append('image', selectedImage);
    } else {
      formData.append('image', product.image); // Append current image path
    }

    try {
      await axios.put(`${baseURL}/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Product updated successfully!');
      navigate('/admin/manage-products');
    } catch (error) {
      console.error('Error updating product:', error);
      setError('Failed to update product. Please try again.');
    }
  };

  // Render a loading message while fetching data
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="edit-product-container">
      <h2>Edit Product</h2>
      {error && <div style={{ color: 'red' }}>{error}</div>} {/* Show error message */}
      <form onSubmit={handleFormSubmit} encType="multipart/form-data">
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={product.name || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <input
            type="text"
            name="description"
            value={product.description || ''}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Price:</label>
          <input
            type="number"
            name="price"
            value={product.price || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Category:</label>
          <input
            type="text"
            name="category"
            value={product.category || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>SKU:</label>
          <input
            type="text"
            name="sku"
            value={product.sku || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Stock:</label>
          <input
            type="number"
            name="stock"
            value={product.stock || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label>Image:</label>
          <input type="file" name="image" onChange={handleImageChange} />
          {imagePreview ? (
            <img src={imagePreview} alt="Product" style={{ width: '100px' }} />
          ) : (
            <img src="/path/to/default-image.jpg" alt="Product" style={{ width: '100px' }} />
          )}
        </div>
        <button type="submit">Update Product</button>
      </form>
    </div>
  );
};

export default EditProduct;
