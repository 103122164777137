import React from 'react';
import { useCart } from '../contexts/CartContext';
import { useCurrency } from '../contexts/CurrencyContext';
import { Link } from 'react-router-dom';
import { Button, Table, Form } from 'react-bootstrap';
import './CartPage.css'; // Custom CSS for additional styling

const CartPage = () => {
  const { cart, removeFromCart, updateItemQuantity, clearCart } = useCart();
  const { convertPrice, currency, getCurrencySymbol, formatPrice } = useCurrency(); // Import formatPrice

  const handleQuantityChange = (productId, quantity) => {
    if (quantity > 0) {
      updateItemQuantity(productId, parseInt(quantity, 10));
    }
  };

  const calculateTotal = () => {
    return formatPrice(
      cart.items.reduce((total, item) => total + convertPrice(item.price) * item.quantity, 0)
    );
  };

  return (
    <div className="cart-page">
      <h1>Your Cart</h1>
      {cart.items.length > 0 ? (
        <>
          <Table striped bordered hover className="cart-table">
            <thead>
              <tr>
                <th>Product</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cart.items.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className="cart-product">
                      <img src={`http://localhost:5000/${item.image}`} alt={item.name} className="cart-product-image" />
                      <Link to={`/products/${item.id}`} className="cart-product-name">
                        {item.name}
                      </Link>
                    </div>
                  </td>
                  <td>{formatPrice(convertPrice(item.price))} {getCurrencySymbol(currency)}</td>
                  <td>
                    <Form.Control
                      type="number"
                      value={item.quantity}
                      min="1"
                      onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                      className="cart-quantity-input"
                    />
                  </td>
                  <td>{formatPrice(convertPrice(item.price) * item.quantity)} {getCurrencySymbol(currency)}</td>
                  <td>
                    <Button variant="danger" onClick={() => removeFromCart(item.id)}>
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="cart-summary">
            <h3>Total: {calculateTotal()} {getCurrencySymbol(currency)}</h3>
            <Button variant="secondary" onClick={clearCart}>
              Empty Cart
            </Button>
            <Link to="/checkout">
              <Button variant="primary" className="checkout-button">
                Proceed to Checkout
              </Button>
            </Link>
          </div>
        </>
      ) : (
        <p>Your cart is empty. <Link to="/">Start shopping</Link>.</p>
      )}
    </div>
  );
};

export default CartPage;
