import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCurrency } from './CurrencyContext';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  // Initialize cart state with data from localStorage or default to empty
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    try {
      const parsedCart = JSON.parse(savedCart);
      // Ensure savedCart is an array
      if (Array.isArray(parsedCart)) {
        return { items: parsedCart }; // Wrap in an object if needed
      }
    } catch (e) {
      console.error("Failed to parse cart from localStorage", e);
    }
    return { items: [] }; // Default to an empty array if parsing fails
  });

  const { currency, convertPrice } = useCurrency();

  // Save cart items to localStorage whenever cart changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart.items));
  }, [cart.items]);

  // Add or update product in the cart
  const addToCart = (product, quantity) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.items.find((item) => item.id === product.id);
      const priceInSelectedCurrency = convertPrice(product.price);

      if (existingProduct) {
        return {
          items: prevCart.items.map((item) =>
            item.id === product.id
              ? { ...item, quantity: item.quantity + quantity, price: priceInSelectedCurrency }
              : item
          )
        };
      } else {
        return {
          items: [
            ...prevCart.items,
            { ...product, quantity, price: priceInSelectedCurrency }
          ]
        };
      }
    });
  };

  // Remove product from the cart
  const removeFromCart = (productId) => {
    setCart((prevCart) => ({
      items: prevCart.items.filter((item) => item.id !== productId)
    }));
  };

  // Update quantity of a product in the cart
  const updateItemQuantity = (productId, quantity) => {
    setCart((prevCart) => ({
      items: prevCart.items.map((item) =>
        item.id === productId ? { ...item, quantity } : item
      )
    }));
  };

  // Clear all items from the cart
  const clearCart = () => {
    setCart({ items: [] });
  };

 
  // Provide cart state and actions to the component tree
  const value = {
    cart,
    addToCart,
    removeFromCart,
    updateItemQuantity,
    clearCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
