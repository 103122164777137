// src/components/Breadcrumbs.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.css'; // Create and import the CSS file for styling

function Breadcrumbs({ path }) {
  const pathSegments = path.split('/').filter(Boolean);

  return (
    <nav className="breadcrumbs">
      <ul>
        <li><Link to="/">Home</Link></li>
        {pathSegments.map((segment, index) => (
          <li key={index}>
            <Link to={`/${pathSegments.slice(0, index + 1).join('/')}`}>
              {segment.charAt(0).toUpperCase() + segment.slice(1)}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Breadcrumbs;
