import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import axios from 'axios';
import { useCart } from '../contexts/CartContext'; 
import { Modal, Button, Form } from 'react-bootstrap'; 
import './ProductPage.css';

function ProductPage() {
  const { id } = useParams(); 
  const { addToCart } = useCart(); 
  const [product, setProduct] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [showQuantityModal, setShowQuantityModal] = useState(false); 
  const [quantity, setQuantity] = useState(1); 
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/products/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Product not found.'); 
      } finally {
        setLoading(false); 
      }
    };

    fetchProduct();
  }, [id]); 

  const handleOpenQuantityModal = () => setShowQuantityModal(true);
  const handleCloseQuantityModal = () => setShowQuantityModal(false);

  const handleAddToCart = () => {
    addToCart(product, quantity); 
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>; 
  }

  return (
    <div className="product-page">
      <h1>{product.name}</h1>
      <img src={`http://localhost:5000/${product.image}`} alt={product.name} />
      <p>{product.description}</p>
      <p>Price: {product.price} KSh</p>
      <Button onClick={handleOpenQuantityModal}>Add to Cart</Button>

      <Modal show={showQuantityModal} onHide={handleCloseQuantityModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="quantitySelect">
            <Form.Label>Quantity:</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuantityModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleAddToCart();
              handleCloseQuantityModal(); 
            }}
          >
            Add to Cart
          </Button>
          <Button
            variant="success"
            onClick={() => {
              handleAddToCart();
              handleCloseQuantityModal(); 
              navigate('/cart'); // Redirect to cart
            }}
          >
            Proceed to Cart
          </Button>
          <Button
            variant="info"
            onClick={() => {
              handleCloseQuantityModal();
              navigate('/'); // Redirect to Homepage
            }}
          >
            Continue Shopping
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductPage;
