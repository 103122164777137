import React, { useEffect, useState } from 'react';
import { Card, Button, Modal, Form } from 'react-bootstrap'; // Import Bootstrap components
import { useCart } from '../contexts/CartContext';
import './ProductListPage.css';

const ProductListPage = () => {
  const [products, setProducts] = useState([]);
  const [showQuantityModal, setShowQuantityModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1); // State for selected quantity
  const { addToCart } = useCart(); // Access cart context

  // Fetch Products
  const fetchProducts = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/products'); // Adjust API endpoint as necessary
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchProducts(); // Fetch products when component mounts
  }, []);

  // Handle opening quantity modal
  const handleOpenQuantityModal = (product) => {
    setSelectedProduct(product);
    setShowQuantityModal(true); // Show quantity modal
  };

  // Handle adding product to cart
  const handleAddToCart = () => {
    addToCart(selectedProduct, quantity); // Add product to cart with selected quantity
    setShowQuantityModal(false); // Close quantity modal
    setShowConfirmationModal(true); // Show confirmation modal
  };

  const handleCloseQuantityModal = () => setShowQuantityModal(false);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  return (
    <div className="product-list-page">
      <h1>Product List</h1>
      <div className="product-list">
        {products.length > 0 ? (
          products.map((product) => (
            <Card key={product.id} className="product-card" style={{ border: 'none' }}>
              <Card.Img variant="top" src={`http://localhost:5000/${product.image}`} alt={product.name} />
              <Card.Body>
                <h3>{product.name}</h3>
                <p>Price: KSh {product.price}</p>
                <Button variant="primary" onClick={() => handleOpenQuantityModal(product)}>
                  Add to Cart
                </Button>
              </Card.Body>
            </Card>
          ))
        ) : (
          <p>No products available.</p>
        )}
      </div>

      {/* Quantity Selection Modal */}
      <Modal show={showQuantityModal} onHide={handleCloseQuantityModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="quantitySelect">
            <Form.Label>Quantity:</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuantityModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddToCart}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Added to Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && `${selectedProduct.name} has been added to your cart.`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmationModal}>
            Continue Shopping
          </Button>
          <Button variant="primary" onClick={() => window.location.href = '/cart'}>
            Go to Cart
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProductListPage;
