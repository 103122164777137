import React, { useEffect, useState } from 'react';
import { Card, Button, Modal, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useCart } from '../contexts/CartContext'; // Import useCart hook
import './CategoryPage.css'; // Import CSS for styling

const CategoryPage = () => {
  const { categoryName } = useParams();
  const [products, setProducts] = useState([]);
  const [showQuantityModal, setShowQuantityModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1); // State for selected quantity
  const { addToCart } = useCart(); // Access the cart context
  const [isLoaded, setIsLoaded] = useState(false); // State to control slide-in effect

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`http://localhost:5000/api/products?category=${encodeURIComponent(categoryName.replace('-', ' '))}`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
    setTimeout(() => {
      setIsLoaded(true); // Trigger slide-in effect after a slight delay
    }, 200); // Delay to allow for the animation
  }, [categoryName]);

  const handleOpenQuantityModal = (product) => {
    setSelectedProduct(product);
    setShowQuantityModal(true); // Show quantity modal
  };

  const handleAddToCart = () => {
    addToCart(selectedProduct, quantity); // Add product to cart with selected quantity
    setShowQuantityModal(false); // Close quantity modal
    alert(`${selectedProduct.name} has been added to your cart.`); // Simple alert for confirmation
  };

  return (
    <div>
      <h2>Category: {categoryName.replace('-', ' ')}</h2>
      <div className="category-product-list">
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product.id} className={`product-item ${isLoaded ? 'slide-in' : ''}`}>
              <Card.Img variant="top" src={`http://localhost:5000/${product.image}`} alt={product.name} />
              <h3>{product.name}</h3>
              <p>Price: KSh {product.price}</p>
              <Button variant="primary" onClick={() => handleOpenQuantityModal(product)}>
                Add to Cart
              </Button>
            </div>
          ))
        ) : (
          <p>No products found in this category.</p>
        )}
      </div>

      {/* Quantity Selection Modal */}
      <Modal show={showQuantityModal} onHide={() => setShowQuantityModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="quantitySelect">
            <Form.Label>Quantity:</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowQuantityModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddToCart}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CategoryPage;
