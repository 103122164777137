import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs'; // Import Breadcrumbs
import ChatboxIcon from '../components/ChatboxIcon'; // Import the ChatboxIcon component
import './ContactPage.css';

function ContactPage() {
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p>We'd love to hear from you! Reach out to us with any questions or feedback.</p>
      <form className="contact-form" autoComplete="on">
        <label htmlFor="name">
          Name:
          <input
            type="text"
            id="name"
            name="name"
            autoComplete="name"
            required
          />
        </label>
        <label htmlFor="email">
          Email:
          <input
            type="email"
            id="email"
            name="email"
            autoComplete="email"
            required
          />
        </label>
        <label htmlFor="message">
          Message:
          <textarea
            id="message"
            name="message"
            autoComplete="off"
            required
          ></textarea>
        </label>
        <button type="submit" className="btn-primary">Send Message</button>
      </form>
      <ChatboxIcon /> {/* Add the ChatboxIcon here */}
    </div>
  );
}

export default ContactPage;
