import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import ProductListPage from './pages/ProductListPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import ContactPage from './pages/ContactPage';
import Layout from './components/Layout'; // Import Layout component
import { CartProvider } from './contexts/CartContext'; // Import CartProvider
import AdminDashboard from './admin/AdminDashboard'; // Import AdminDashboard component
import AddProduct from './admin/AddProduct'; // Import AddProduct component
import ManageProducts from './admin/ManageProducts'; // Import ManageProducts component
import ManageOrders from './admin/ManageOrders'; // Import ManageOrders component
import NotFoundPage from './pages/NotFoundPage'; // Import NotFoundPage component
import EditProduct from './admin/EditProduct'; 
import CategoryPage from './pages/CategoryPage'; // Import CategoryPage component
import AddPickupLocation from './admin/AddPickupLocation'; // Import AddPickupLocation component
import PickupLocationManager from './admin/PickupLocationManager'; // Import PickupLocationManager component


function App() {
  return (
    <Router>
      <CartProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<ProductListPage />} />
            <Route path="/product/:id" element={<ProductPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/add-product" element={<AddProduct />} />
            <Route path="/admin/manage-products" element={<ManageProducts />} />
            <Route path="/admin/manage-orders" element={<ManageOrders />} />
            <Route path="/admin/edit-product/:id" element={<EditProduct />} />
            <Route path="/admin/add-pickup-location" element={<AddPickupLocation />} />
            <Route path="/admin/manage-pickup-locations" element={<PickupLocationManager />} />
            <Route path="/category/:categoryName" element={<CategoryPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </CartProvider>
    </Router>
  );
}

export default App;
