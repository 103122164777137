// src/components/CurrencyDropdown.js
import React from 'react';
import { useCurrency } from '../contexts/CurrencyContext';

function CurrencyDropdown() {
  const { currency, setCurrency } = useCurrency();

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const currencyOptions = [
    { value: 'KSHS', label: 'Kenyan Shilling' },
    { value: 'USD', label: 'US Dollar' },
    { value: 'TZS', label: 'Tanzanian Shilling' },
    { value: 'UGX', label: 'Ugandan Shilling' },
    { value: 'EUR', label: 'Euro' },
    { value: 'GBP', label: 'British Pound' },
  ];

  return (
    <select
      value={currency}
      onChange={handleCurrencyChange}
      className="currency-dropdown"
    >
      {currencyOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default CurrencyDropdown;
