import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ChatboxIcon from './ChatboxIcon';
import Breadcrumbs from './Breadcrumbs';
import CurrencyDropdown from './CurrencyDropdown'; // Ensure this import is correct
import './Layout.css';
import { useLocation } from 'react-router-dom';
import { useCurrency } from '../contexts/CurrencyContext';
import { useCart } from '../contexts/CartContext'; // Import useCart hook

function Layout({ children }) {
  const location = useLocation();
  const path = location.pathname;
  const { setCurrency } = useCurrency(); // Keep only setCurrency from useCurrency context

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <div className="layout">
      <Header />
      <Breadcrumbs path={path} />
      <div className="currency-switch">
        <CurrencyDropdown onChange={handleCurrencyChange} /> {/* Pass handler to CurrencyDropdown */}
      </div>
      <main>{children}</main>
      <ChatboxIcon />
      <Footer />
    </div>
  );
}

export default Layout;
