import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form } from 'react-bootstrap';

const PickupLocationManager = () => {
  const [pickupLocations, setPickupLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editLocation, setEditLocation] = useState(null);
  const [formData, setFormData] = useState({
    region: '',
    area: '',
    sub_area: '', // Use sub_area for consistency
    name: '',
    price: ''
  });

  useEffect(() => {
    fetchPickupLocations();
  }, []);

  const fetchPickupLocations = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/pickup-locations');
      setPickupLocations(response.data);
    } catch (error) {
      console.error('Error fetching pickup locations:', error);
    }
  };

  const handleEditClick = (location) => {
    setEditLocation(location);
    setFormData({
      region: location.region,
      area: location.area,
      sub_area: location.sub_area, // Correct use of sub_area
      name: location.name,
      price: location.price
    });
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/api/pickup-locations/${id}`);
      fetchPickupLocations();
    } catch (error) {
      console.error('Error deleting location:', error);
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`http://localhost:5000/api/pickup-locations/${editLocation.id}`, {
        region: formData.region,
        area: formData.area,
        sub_area: formData.sub_area, // Correct use of sub_area
        name: formData.name,
        price: formData.price
      });
      setShowModal(false);
      fetchPickupLocations();
    } catch (error) {
      console.error('Error updating location:', error);
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  return (
    <div>
      <h2>Pickup Location Manager</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '80px' }}>Region</th>
            <th style={{ width: '80px' }}>Area</th>
            <th style={{ width: '80px' }}>Sub-Area</th>
            <th style={{ width: '300px' }}>Agent Name</th>
            <th style={{ width: '70px' }}>Price</th>
            <th style={{ width: '100px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pickupLocations.map((location) => (
            <tr key={location.id}>
              <td>{location.region}</td>
              <td>{location.area}</td>
              <td>{location.sub_area}</td> {/* Correctly using sub_area */}
              <td>{location.name}</td>
              <td>{location.price}</td>
              <td>
                <Button variant="warning" onClick={() => handleEditClick(location)}>Edit</Button>{' '}
                <Button variant="danger" onClick={() => handleDelete(location.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Editing Pickup Location */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Pickup Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditSubmit}>
            <Form.Group controlId="region">
              <Form.Label>Region</Form.Label>
              <Form.Control
                type="text"
                name="region"
                value={formData.region}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="area">
              <Form.Label>Area</Form.Label>
              <Form.Control
                type="text"
                name="area"
                value={formData.area}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="sub_area"> {/* Use sub_area */}
              <Form.Label>Sub-Area</Form.Label>
              <Form.Control
                type="text"
                name="sub_area"
                value={formData.sub_area}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="name">
              <Form.Label>Agent Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={formData.price}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PickupLocationManager;
